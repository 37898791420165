import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import { isUserAgentVersionSupported } from '@/common/helpers/browsers';

export const initializeSentry = () => {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_REACT_DSN,
        integrations: [
            new BrowserTracing(),
            new Sentry.Replay({
                maskAllText: true,
                blockAllMedia: false,
            }),
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
        tracesSampleRate: 1.0,
        enabled: isUserAgentVersionSupported(),
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
};

export default Sentry;
