/**
 * External dependencies
 */
import { useState, createContext, useContext } from 'react';

const DrawersContext = createContext({});

export const DrawersContextProvider = ({ children }) => {
    const [activeDrawer, setActiveDrawer] = useState(null);
    const [drawerMenuBackTitles, setDrawerMenuBackTitles] = useState([]);

    const getDrawerMenuBackTitle = () => {
        const backTitlesLength = drawerMenuBackTitles.length;

        return backTitlesLength > 0 ? drawerMenuBackTitles[backTitlesLength - 1] : '';
    };

    const addDrawerMenuBackTitle = (newTitle) => {
        setTimeout(() => {
            setDrawerMenuBackTitles((previousTitles) => [...previousTitles, newTitle]);
        }, 150);
    };

    const removeLastDrawerMenuBackTitle = () =>
        setDrawerMenuBackTitles((previousTitles) => [...previousTitles.slice(0, -1)]);

    const resetMenuBackTitles = () => setDrawerMenuBackTitles([]);

    const values = {
        activeDrawer,
        setActiveDrawer,
        drawerMenuBackTitles,
        resetMenuBackTitles,
        getDrawerMenuBackTitle,
        addDrawerMenuBackTitle,
        removeLastDrawerMenuBackTitle,
    };

    return <DrawersContext.Provider value={values}>{children}</DrawersContext.Provider>;
};

export default function useDrawersContext() {
    return useContext(DrawersContext);
}
