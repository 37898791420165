/**
 * External dependencies
 */
import * as lite from 'caniuse-lite';
import UAParser from 'ua-parser-js';

// Count of last major supported versions.
const lastBrowserMajorVersionsCount = 2;

const trimToMajorVersion = (version) => parseInt(version.split('.')[0]);

const getLatestMajorBrowserVersions = (browser) => {
    const browserData = lite.agents[browser];

    if (!browserData) {
        return;
    }

    const versions = Object.values(browserData.versions);
    const majorBrowserVersions = [];
    let loopFlag = true;

    while (loopFlag) {
        const lastVersion = versions.pop()?.toString();

        if (!lastVersion) {
            continue;
        }

        const isMajor = lastVersion.match(/^(\d|[a-zA-Z])+(\.0)?$/gm);
        const isReleased = browserData.release_date[lastVersion];

        const isCollectionFilled = majorBrowserVersions.length === lastBrowserMajorVersionsCount;

        if (isMajor && isReleased && !isCollectionFilled) {
            const lastVersionTrimmed = trimToMajorVersion(lastVersion);
            majorBrowserVersions.push(lastVersionTrimmed);
        }

        if (isCollectionFilled) {
            loopFlag = false;
        }
    }
    return majorBrowserVersions;
};

const isUserAgentVersionSupported = () => {
    const parser = new UAParser();
    const browser = parser.getBrowser();
    const isIE = browser === 'IE';

    if (!browser || isIE) {
        return;
    }
    const latestTwoVersionOfUserAgent = getLatestMajorBrowserVersions(browser.name.toLowerCase());

    if (!latestTwoVersionOfUserAgent) {
        return;
    }

    const majorBrowserVersion = trimToMajorVersion(browser.version);

    // At the time this is being developed caniuse-lite has missed to include Opera latest version -- 96.
    // In order to catch all non-updated latest versions, we check if user agent version is bigger than
    // the one returned from caniuse-lite, and we consider that we support it.
    const isUserAgentVersionBiggerThanLatestInCaniuse = majorBrowserVersion > parseInt(latestTwoVersionOfUserAgent[0]);

    return latestTwoVersionOfUserAgent.includes(majorBrowserVersion) || isUserAgentVersionBiggerThanLatestInCaniuse;
};

export { isUserAgentVersionSupported };
