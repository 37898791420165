/**
 * External dependencies
 */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas as fasPro } from '@fortawesome/pro-solid-svg-icons';
import { far as farPro } from '@fortawesome/pro-regular-svg-icons';

const loadFontAwesome = () => library.add(farPro, fasPro);

export default loadFontAwesome;
